import { Injectable, signal } from '@angular/core'

export type NavItems = 'resumen' | 'autoejecucion' | 'radiografia'

@Injectable({
  providedIn: 'root'
})
export class NavService {
  #modeSignal = signal<NavItems | null>(null)
  mode = this.#modeSignal.asReadonly()

  changeMode(value: NavItems) {
    this.#modeSignal.set(value)
  }
}
