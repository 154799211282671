import { NgClass } from '@angular/common'
import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { ActivatedRoute, RouterLink } from '@angular/router'
import { NavService } from '@shared/services/nav-service.service'

@Component({
  selector: 'app-principal-tabs',
  standalone: true,
  imports: [NgClass, RouterLink],
  templateUrl: './principal-tabs.component.html',
  styleUrl: './principal-tabs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrincipalTabsComponent {
  navService = inject(NavService)
  route = inject(ActivatedRoute)

  mode = this.navService.mode

  constructor() {
    this.route.data.subscribe(data => {
      const nav = data['nav']
      this.navService.changeMode(nav)
    })
  }
}
