<div class="bg-theme-darkBlue shadow-inset-custom-2 text-sm rounded-3xl grid grid-cols-3 items-stretch gap-4 py-2 px-2 text-white">
  <a
    routerLink="/"
    class="text-center rounded-2xl p-2 font-semibold cursor-pointer transition-colors"
    [ngClass]="{ 'bg-theme-gradient-orange text-theme-darkBlue': mode() === 'resumen' }">
    <p>Resumen</p>
  </a>
  <a
    routerLink="/autoejecucion"
    class="text-center rounded-2xl p-2 font-semibold cursor-pointer transition-colors"
    [ngClass]="{ 'bg-theme-gradient-orange text-theme-darkBlue': mode() === 'autoejecucion' }">
    <p>Autoejecución</p>
  </a>
  <a
    routerLink="/radiografia"
    class="text-center rounded-2xl p-2 font-semibold cursor-pointer transition-colors"
    [ngClass]="{ 'bg-theme-gradient-orange text-theme-darkBlue': mode() === 'radiografia' }">
    <p>Radiografía</p>
  </a>
</div>
